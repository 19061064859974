import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/root/workspace/src/templates/markdown-page.js";
import ubug from '../images/logos/ubug5.svg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hello World!`}</h1>
    <p>{`我是 `}<inlineCode parentName="p">{`Ubug`}</inlineCode>{` 🎉`}</p>
    <p>{`我是一个软件开发工程师者 👨‍💻，94 年天蝎座，北漂中。目前主要工作是 Web / H5 大前端开发方向。能全栈干活，熟悉 Flutter、Node.js、PHP、Android、JAVA、Python 等语言开发，对 Flutter 应用、Web IDE、前端工程化有较深入了解，懂一些产品设计、视觉设计、视频编辑等，会用 PS、AI、AE 等设计软件，技术面较广，对新技术很感兴趣。喜欢折腾，把想法快速实践。`}</p>
    <blockquote>
      <p parentName="blockquote">{`✉️ `}<a parentName="p" {...{
          "href": "mailto:findme@ubug.io"
        }}>{`findme@ubug.io`}</a>{` - 联系我!`}</p>
    </blockquote>
    <hr></hr>
    <img {...{
      "src": "/4e8d08c3293b4f75f52fc0d3be3416dd/ubug5.svg",
      "style": {
        "maxWidth": "90px"
      },
      "alt": "Ubug Logo"
    }}></img>
    <h2>{`🐞 Ubug`}</h2>
    <p>{`随便起的名字，bug 前面一个 u`}</p>
    <ul>
      <li parentName="ul">{`U.bug`}</li>
      <li parentName="ul">{`Bug U.`}</li>
      <li parentName="ul">{`ubug`}</li>
    </ul>
    <p>{`📣 读音可以当作 `}<inlineCode parentName="p">{`/u:/`}</inlineCode>{` (乌)读做 `}{`[无 bug]`}{` 或者分开读 `}{`[有 bug]`}{`。`}</p>
    <hr></hr>
    <p>{`一些链接：`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tools"
        }}>{`🧰 生产力工具`}</a>{` - 我平时用的生产力工具`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      